// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-mobile-js": () => import("./../../../src/pages/about-mobile.js" /* webpackChunkName: "component---src-pages-about-mobile-js" */),
  "component---src-pages-agreement-js": () => import("./../../../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-mobile-js": () => import("./../../../src/pages/index-mobile.js" /* webpackChunkName: "component---src-pages-index-mobile-js" */),
  "component---src-pages-life-mobile-js": () => import("./../../../src/pages/life-mobile.js" /* webpackChunkName: "component---src-pages-life-mobile-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-property-mobile-js": () => import("./../../../src/pages/property-mobile.js" /* webpackChunkName: "component---src-pages-property-mobile-js" */),
  "component---src-pages-smart-life-js": () => import("./../../../src/pages/smart-life.js" /* webpackChunkName: "component---src-pages-smart-life-js" */),
  "component---src-pages-smart-property-js": () => import("./../../../src/pages/smart-property.js" /* webpackChunkName: "component---src-pages-smart-property-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wuye-agreement-js": () => import("./../../../src/pages/wuye-agreement.js" /* webpackChunkName: "component---src-pages-wuye-agreement-js" */)
}

